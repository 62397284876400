<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="50.000000pt"
    height="25.000000pt"
    viewBox="0 0 208 160"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M1000 1487 c-18 -9 -47 -57 -105 -174 -44 -89 -82 -164 -85 -167 -3
-2 -86 -16 -185 -30 -164 -24 -182 -28 -202 -52 -13 -14 -23 -35 -23 -46 0
-14 48 -69 136 -159 l136 -137 -31 -183 c-28 -167 -29 -186 -15 -207 28 -43
58 -45 127 -11 34 17 63 33 65 34 2 2 -6 22 -18 45 -31 60 -52 174 -45 235 21
174 136 326 293 386 69 26 189 36 262 20 69 -15 143 -52 204 -103 l49 -41 48
51 c55 56 60 79 27 117 -21 23 -39 28 -206 52 l-184 27 -84 168 c-74 148 -102
188 -131 188 -4 0 -19 -6 -33 -13z"
      />
      <path
        d="M1108 957 c-153 -57 -248 -182 -256 -336 -6 -99 11 -167 58 -240 89
-138 263 -202 420 -155 29 9 64 23 77 32 24 15 26 14 111 -71 99 -98 129 -114
171 -92 33 17 41 33 41 82 0 31 -11 47 -89 127 l-88 91 21 45 c17 37 21 64 21
150 0 99 -2 109 -32 171 -39 79 -102 140 -181 177 -46 22 -74 27 -147 29 -57
2 -103 -2 -127 -10z m241 -128 c101 -50 157 -146 149 -254 -7 -75 -33 -129
-91 -182 -106 -98 -274 -94 -376 8 -56 55 -76 105 -76 189 0 65 4 82 30 129
74 133 225 179 364 110z"
      />
    </g>
  </svg>
</template>
